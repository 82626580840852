<template>
  <div class="global-blue bg">
    <v-container>

    <v-row>
      <v-col>
        <!-- <p class="global-title ma-8">{{translations.title[locale]}}</p> -->
        <p class="global-title-sm hidden-md-and-up mt-12">{{ translations.title[locale] }}</p>
        <p class="global-title hidden-sm-and-down">{{ translations.title[locale] }}</p>
      </v-col>
    </v-row>
    <!-- <v-row class="ma-3" v-for="(t, i) in translations.tools" :key="i" >
        <v-col>
          <v-card href="http://localhost:8081" class="tools" dark outlined >
            <h1>{{ t.title[locale] }}</h1>
            <br>
            <p>{{ t.text[locale] }}</p>
          </v-card>
        </v-col>
    </v-row> -->
    <v-row class="ma-3">
      <v-col>
        <v-card
          :href="trlUrl"
          class="tools"
          outlined
          dark
        >
          <h1>{{ locale == 'fi' ? 'TRL-työkalu' : 'TRL evaluation tool' }}</h1>
          <br>
          <p>{{ locale == 'fi' ? 'Kehittämisympäristön teknologian valmiustason arvioimiseen' : 'For evaluation of the technology readiness of the development environment' }}</p>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-3">
      <v-col>
        <v-card
          :href="isaUrl"
          class="tools"
          dark
          outlined
        >
          <h1>{{ locale == 'fi' ? 'KV-kasvun rappuset' : 'Stairs to international growth' }}</h1>
          <br>
          <p>{{ locale == 'fi' ? 'Työkalu kansainvälisyyden tason arviointiin yrityksille' : 'Tool for the assessment of the level of internationalization for companies' }}</p>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-3">
      <v-col>
        <v-card
          :href="digiUrl"
          class="tools"
          dark
          outlined
        >
          <h1>{{ translations.tools[3].title[locale] }}</h1>
          <br>
          <p>{{ translations.tools[3].text[locale] }}</p>
        </v-card>
      </v-col>
    </v-row>
    <template v-if="loginStatus == true">
      <v-row class="ma-3">
        <v-col>
          <v-card
            :href="authUrl"
            class="tools"
            dark
            outlined
          >
            <h1>{{ locale == 'fi' ? 'Käyttäjien asetukset' : 'User settings' }}</h1>
            <br>
            <p>{{ locale == 'fi' ? 'Työkalu käyttäjille tietojen hallintaa varten' : 'Tool for users to manage their data' }}</p>
          </v-card>
        </v-col>
      </v-row>
    </template>

    </v-container>
  </div>
</template>

<style scoped>
.bg {
  height: 100%;
}
.global-title {
  text-align: left;
}
h1 {
  color: #f7941e;
  font-size: 22px;
  font-weight: 500;
}

.tools {
  cursor: pointer;
  color: white;
  background: var(--v-primary-base) !important;
  padding: 12px;
  font-size: 14px;
}
</style>

<script>
import { PORTAL_TOOLS } from "../../constants/translations";

export default {
  name: "Tools",
  data() {
    return {
      translations: PORTAL_TOOLS,
      trlUrl: process.env.VUE_APP_TRL_URL,
      isaUrl: process.env.VUE_APP_ISA_URL,
      authUrl: process.env.VUE_APP_AUTH_URL,
      digiUrl: process.env.VUE_APP_DIGI_URL
    };
  },
  methods: {},
  computed: {
    locale() {
      return this.$store.state.locale;
    },
    loginStatus() {
      return this.$store.state.loginStatus;
    }
  }
};
</script>